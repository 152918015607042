/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Aunque los diseñadores y desarrolladores de hardware y software no nos demos cuenta, ya no estamos en los tiempos de Edison y Bell, estamos en el siglo XXI, pero nosotros seguimos con esa idea de que las patentes son buenas porque nos dan dinero."), "\n", React.createElement(_components.p, null, "Pero nos equivocamos, hoy en día los únicos que se benefician de las patentes son las empresas. Actualmente las grandes empresas son las lideres en innovación (no es el caso de España donde tristemente la innovación solo se encuentra en las universidades), estas grandes empresas son las que cuentan con uno o varios grupos de empleados a sueldo que se encargan de la investigación y el desarrollo."), "\n", React.createElement(_components.p, null, "Tomando como básico este modelo veamos como afectan las patente en el trabajo del investigador y el diseñador. Cuando trabajas para una empresa y tienes un contrato donde declaras que todo lo que tu desarrolles para ésta será propiedad de la empresa, las patentes dejan de tener importancia para ti, porque la empresa pondrá lo que tu has desarrollado a su nombre en esa patente y si te despiden en algún momento no tendrás ninguna compensación sin embargo la empresa continuará teniendo beneficios a costa de lo que tu has desarrollado."), "\n", React.createElement(_components.p, null, "Ésto unido a que las patentes son un impedimento para el desarrollo, puesto que encarecen todos los nuevos productos, me ha convencido para dejar de lado las patente y luchar por un nuevo sistema mas justo, por lo que he decidido defender el Open Source, como un nuevo modelo de desarrollo mas cercano entre usuarios y diseñadores y sobretodo mas justo para todos."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
